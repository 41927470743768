import * as React from "react"
import { Button, FormControl, InputLabel, Select, FilledInput, MenuItem, } from "@mui/material"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Colors, Padding } from "../components/Styles";
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { useEffect, useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { PPEButton } from "../components/PPEButton";


const buttonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Corben",
          fontSize: '1.5rem',
          backgroundColor: Colors.purple,
          textTransform: 'none',
          borderRadius: '30px',
          width: '150px',
          lineHeight: '0',
          '&:hover': {
            backgroundColor: Colors.lightPurple,
            transition: "0.3s ease-out"
          },
        },
      },
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.purple,
    },
    secondary: {
      main: Colors.pink,
    },
  },
  components: {
    MuiFormLabel: {
      color: Colors.slate,
      styleOverrides: {
        root: {
          fontFamily: "Corben",
          color: Colors.slate
        }
      }
    },
    MuiInputLabel: {
      color: Colors.slate,
      styleOverrides: {
        root: {
          fontFamily: "Corben",
          color: Colors.slate
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Corben",
          color: Colors.slate
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root:
        {
          fontFamily: "Corben",
          backgroundColor: Colors.pink,
          color: Colors.slate
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root:
        {
          fontFamily: "Corben",
          backgroundColor: Colors.pink,
          width: "390px",
          margin: "10px",
          color: Colors.slate,
          '& .MuiFilledInput-input': {
            backgroundColor: Colors.pink,
            padding: '10px'
          }
        }
      }
    },
  }
});

const mobileTheme = createTheme({
  palette: {
    primary: {
      main: Colors.purple,
    },
    secondary: {
      main: Colors.pink,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: "Corben",
          width: '100%',
          marginLeft: "10px",
          marginRight: "10px",
          backgroundColor: Colors.pink,
        }
      }
    }
  }
})

const desktopTheme = createTheme({
  palette: {
    primary: {
      main: Colors.purple,
    },
    secondary: {
      main: Colors.pink,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: "Corben",
          width: '100%',
          marginLeft: "10px",
          marginRight: "10px",
          maxWidth: "800px",
          backgroundColor: Colors.pink,
        }
      }
    }
  }
})



const altTheme = createTheme({
  palette: {
    primary: {
      main: Colors.purple,
    },
    secondary: {
      main: Colors.pink,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root:
        {
          fontFamily: "Corben",
          backgroundColor: Colors.pink,
          padding: '0px !important',
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root:
        {
          fontFamily: "Corben",
          backgroundColor: Colors.pink,
          background: Colors.pink,
          width: "800px",
          margin: "10px",
          color: Colors.slate,
          '& .MuiFilledInput-input': {
            backgroundColor: Colors.pink,
            padding: '10px'
          },
        }
      }
    },
  }
});

const ContactPage = () => {
  const [service, setService] = React.useState('');
  const [isDesktop, setDesktop] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 920)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  if (isDesktop == true) {
    return (
      <div>
        <div style={{ display: "flex", backgroundColor: Colors.cream }}>
          <StaticImage
            style={{ zIndex: "90", width: "50%" }}
            src="../images/contact1.jpg"
            loading="eager"
            quality={100}
            objectFit="fill"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            alt="A photo from a gender reveal party hosted by Sparkle Celebrations." />
          <div style={{ width: `45%`, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: Padding.gutter }}>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
              <h1>Ready to Party?</h1>
              <h2>Like what you see and want to book your complimentary consultation? Or maybe you're already in love and ready to start planning! Book with us now so we can start planning the party, shower, or event of your dreams!</h2>
              <PPEButton centered buttonText="Contact Form" externalLink="https://www.honeybook.com/widget/sparkle_celebrations_241268/cf_id/637e722fdb98740028d0dffc" />
            </AnimationOnScroll>
          </div>
        </div>
        <div style={{ paddingTop: "20px", display: "flex", justifyContent: "center", backgroundColor: Colors.purple, textAlign: "center" }}>
          <div style={{ color: Colors.cream, justifyContent: "center" }}>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
              <h1>Give us a call!</h1>
              <h2>You can reach us from 8am-5pm at (720)-213-8777</h2>
            </AnimationOnScroll>
          </div>
        </div>
      </div >
    )
  }
  else if (isDesktop == false) return (
    <div style={{ paddingTop: "95px" }}>
      <div style={{ width: `100%`, paddingTop: "15px", paddingBottom: "15px", justifyContent: 'center', paddingLeft: Padding.mobileGutter, backgroundColor: Colors.cream }}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
          <h1 style={{ fontSize: '50px' }}>Ready to Party?</h1>
          <h2 style={{ fontSize: '18px' }}>Like what you see and want to book your complimentary consultation? Or maybe you're already in love and ready to start planning! Book with us now so we can start planning the party, shower, or event of your dreams!</h2>
          <PPEButton mobile centered buttonText="Contact Form" externalLink="https://www.honeybook.com/widget/sparkle_celebrations_241268/cf_id/637e722fdb98740028d0dffc" />
        </AnimationOnScroll>
      </div>
      <StaticImage
        src="../images/contact1.jpg"
        loading="eager"
        quality={100}
        objectFit="fill"
        layout="fullWidth"
        formats={["auto", "webp", "avif"]}
        alt="A photo from a gender reveal party hosted by Sparkle Celebrations." />
      <div style={{ paddingTop: "20px", display: "flex", justifyContent: "center", backgroundColor: Colors.purple, textAlign: "center" }}>
        <div style={{ color: Colors.cream, justifyContent: "center" }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
            <h1>Give us a call!</h1>
            <h2>You can reach us from 8am-5pm at (720)-213-8777</h2>
          </AnimationOnScroll>
        </div>
      </div>
    </div >
  )
}

export const Head = () => <Seo title="Contact" />

export default ContactPage